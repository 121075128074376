<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12>
      <v-flex xs12 class="pa-3 pb-0">
        <div class="expande-horizontal centraliza">
          <div class="expande-horizontal column title-item">
            <v-avatar
              @click="$router.go(-1)"
              size="36"
              style="cursor: pointer;"
              color="#555"
              icon
            >
              <v-icon color="#f2f2f2" size="26">mdi-arrow-left</v-icon>
            </v-avatar>
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center; height: 40px;"
          >
            <v-icon color="#f2f2f2" size="38" style="padding-bottom: 3px;"
              >mdi-cash-fast</v-icon
            >
            <span
              style="font-size: 19pt; text-wrap: nowrap;"
              class="fonte-bold white--text"
            >
              Saque
            </span>
          </div>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="expande-horizontal centraliza">
          <v-window touchless v-model="step">
            <v-window-item value="0">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold white--text"
                      style="font-size: 24pt;"
                    >
                      Escolha de onde deseja sacar
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-list class="px-3" color="transparent">
                      <v-list-item
                        @click="selectWithdrawType('unilevel')"
                        style="background: #333; border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            Rede
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            Ganhos da rede
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item
                        @click="selectWithdrawType('pl')"
                        style="background: #333; border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            PL
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            Participação de Lucros
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item
                        v-if="false"
                        @click="selectWithdrawType('investment')"
                        style="background: #333; border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            Rentabilidade
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            Seus ganhos
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>

                      <!-- <v-list-item
                        @click="selectWithdrawType('purchase')"
                        style="background: #333; border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            Aportes
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            Valores aportados
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item> -->
                    </v-list>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>

            <v-window-item value="1">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold white--text"
                      style="font-size: 24pt;"
                    >
                      Escolha a conta de destino
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-list class="px-3" color="transparent">
                      <v-list-item
                        v-for="account in accounts"
                        :key="account._id"
                        @click="selectAccount(account)"
                        style="background: #333; border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            {{ account.bank }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            {{ account.info }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>

            <v-window-item value="2">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex xs12>
                    <v-list dark class="fonte" color="transparent">
                      <v-list-item class="px-0">
                        <v-avatar color="#555" rounded class="mr-2">
                          <v-icon color="#f2f2f2">mdi-cash</v-icon>
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ getRequestPayment.tipo_saque }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Origem
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-avatar color="#555" rounded class="mr-2">
                          <v-icon color="#f2f2f2"
                            >mdi-send</v-icon
                          >
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ target_account.bank }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Destino
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-avatar color="#555" rounded class="mr-2">
                          <v-icon color="#f2f2f2">mdi-cash</v-icon>
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $helper.formataSaldo(balance.balance || 0) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Saldo disponível
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold white--text"
                      style="font-size: 24pt;"
                    >
                      Valor do saque
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      filled
                      color="white"
                      rounded
                      dark
                      prefix="$"
                      v-mask="[
                        '#',
                        '##',
                        '###',
                        '####',
                        '#####',
                        '######',
                        '#########'
                      ]"
                      large
                      v-model="getRequestPayment.value"
                      autofocus
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <div class="expande-horizontal column centraliza">
                      <v-btn
                        @click="submit"
                        :loading="loading"
                        x-large
                        color="#555"
                        dark
                        rounded
                        class="fonte-bold"
                        >Solicitar</v-btn
                      >
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>
            <v-window-item value="3">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold mb-6 expande-horizontal centraliza"
                      style="font-size: 24pt;"
                    >
                      <v-avatar size="120" color="green">
                        <v-icon color="white" size="100">mdi-check</v-icon>
                      </v-avatar>
                    </span>
                  </v-flex>
                  <v-flex class="px-1" xs12>
                    <div class="expande-horizontal centraliza column">
                      <span class="fonte-bold white--text" style="font-size: 24pt;">
                        Saque solicitado
                      </span>
                      <span
                        class="fonte grey--text mb-2"
                        style="font-size: 12pt;"
                      >
                        Seu saque está sendo processado!
                      </span>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="$router.push('/dashboard')"
                        color="black"
                        dark
                        large
                        rounded
                        class="fonte-bold"
                        >Ir para o início</v-btn
                      >
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>
            <v-window-item value="4">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold mb-6 expande-horizontal centraliza"
                      style="font-size: 24pt;"
                    >
                      <v-avatar size="120" color="orange">
                        <v-icon color="white" size="60"
                          >mdi-alert-outline</v-icon
                        >
                      </v-avatar>
                    </span>
                  </v-flex>
                  <v-flex class="px-1" xs12>
                    <div class="expande-horizontal centraliza column">
                      <span class="fonte-bold white--text" style="font-size: 24pt;">
                        Opa!
                      </span>
                      <span
                        class="fonte grey--text mb-2"
                        style="font-size: 12pt;"
                      >
                        {{ error_message }}
                      </span>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="step = '2'"
                        color="black"
                        dark
                        large
                        rounded
                        class="fonte-bold"
                        >Tentar novamente</v-btn
                      >
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="$router.push('/dashboard')"
                        color="white"
                        dark
                        text
                        class="fonte-bold"
                        >Ir para o início</v-btn
                      >
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>
          </v-window>
        </div>
      </v-flex>
    </v-flex>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["transaction_type"],
  data() {
    return {
      channel_code: "",
      user_code: "",
      target_account: {},
      accounts: [],
      step: "0",
      value: "",
      loading: false,
      error_message: ""
    };
  },
  methods: {
    ...mapActions([
      "getDestinationUser",
      "requestWithdraw",
      "list_accounts",
      "create_account",
      "show_pinbank_account",
      "listBalanceUnilevel",
      "listBalancePL",
      "listBalanceInvestment",
      "listBalancePurchase",
      "requestPayment"
    ]),
    selectWithdrawType(type) {
      this.transaction_type = type;
      this.getRequestPayment.tipo_saque = type;
      this.get = type;
      if (type === "unilevel") {
        this.listBalanceUnilevel();
      }
      if (type === "pl") {
        this.listBalancePL();
      }
      if (type === "investment") {
        this.listBalanceInvestment();
      }
      if (type === "purchase") {
        this.listBalancePurchase();
      }
      this.step = "1";
    },
    selectAccount(account) {
      this.target_account = account;
      this.getRequestPayment.payment_method = account;
      this.step = "2";
    },
    submit() {
      if (this.getRequestPayment.payment_method === "") {
        this.error_message = "Selecione uma conta";
        this.step = "4";
        return;
      }
      if (this.getRequestPayment.value === "") {
        this.error_message = "Insira um valor";
        this.step = "4";
        return;
      }
      if (parseInt(this.getRequestPayment.value) < 1) {
        this.error_message = "Insira um valor maior que zero";
        this.step = "4";
        return;
      }
      if (parseInt(this.getRequestPayment.value) > parseInt(this.balance.balance)) {
        this.error_message = "Saldo insuficiente";
        this.step = "4";
        return;
      }
      this.requestPayment()
        .then(() => {
          this.step = "3";
        })
        .catch(err => {
          this.error_message = err;
          this.step = "4";
        });
    },
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "getBalanceInvestment",
      "getBalanceUnilevel",
      "getBalancePL",
      "getBalancePurchase",
      "getRequestPayment"
    ]),
    balance() {
      if (this.getRequestPayment.tipo_saque === "unilevel") {
        return this.getBalanceUnilevel;
      }
      if (this.getRequestPayment.tipo_saque === "pl") {
        return this.getBalancePL;
      }
      if (this.getRequestPayment.tipo_saque === "investment") {
        return this.getBalanceInvestment;
      }
      if (this.getRequestPayment.tipo_saque === "purchase") {
        return this.getBalancePurchase;
      }
      return "0.00";
    }
  },
  created() {
    // setInterval(() => {
    //   if (this.step == '4') {
    //     this.step = "1";
    //   } else {
    //     this.step = `${parseInt(this.step) + 1}`;
    //   }
    // }, 4000)
    this.list_accounts()
      .then(res => {
        this.accounts = res;
        if (this.transaction_type) {
          this.selectWithdrawType(this.transaction_type);
        }
      })
      .catch(err => {
        this.error_message = err.response.data.message;
      });
  }
};
</script>
